import axios from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RoutePath } from 'src/router';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';

import { PageLoading, Breadcrumbs } from '@itm/shared-frontend/lib/components';
import { ServerErrorAdapter } from '@itm/shared-frontend/lib/utils';
import { ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import { TabLayout } from '@itm/shared-frontend/lib/components/layout';
import type { TabOption } from '@itm/shared-frontend/lib/components/layout/TabLayout';
import type { Breadcrumb } from '@itm/shared-frontend/lib/components/Breadcrumbs';

import ProjectDetails from './ProjectDetails';
import ProjectRAGDetails from './ProjectRAGDetails';

import { getProjectById } from 'src/api/biReporting/project';

import { ProjectResponse, ServerError, ServerFormErrors } from 'src/types';

type RouteParams = {
  projectId: string;
};

type Props = {
  isEdit: boolean;
};

function Project({ isEdit }: Props) {
  const { projectId } = useParams<RouteParams>();
  const [project, setProject] = useState<ProjectResponse>();
  const [serverErrorMessages, setServerErrorMessages] = useState<ServerFormErrors>([]);
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const breadcrumbsBase = useMemo<Breadcrumb[]>(
    () => [
      { name: 'BI Reporting', to: RoutePath.root },
      { name: 'Data Management', to: RoutePath.dataManagementRoot },
      { name: 'All Projects', to: RoutePath.dataManagementProjectList },
    ],
    [],
  );

  const [breadcrumbs, setBreadcrumbs] = useState(breadcrumbsBase);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsBase);
  }, [breadcrumbsBase]);

  const tabLayoutOptions = useMemo<TabOption[]>(
    () => [
      { path: 'project-detail', tabTitle: 'Project Detail' },
      { path: 'project-rag-detail', tabTitle: 'Project RAG Detail' },
    ],
    [],
  );

  const fetchProject = useCallback(async () => {
    if (!projectId) return;
    const abortController = new AbortController();
    abortControllerSet.add(abortController);
    setServerErrorMessages([]);
    setIsLoading(true);
    try {
      const result = await getProjectById(projectId, { signal: abortController.signal });
      setProject(result.data);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      const serverErrors = new ServerErrorAdapter(e as ServerError);
      setServerErrorMessages(serverErrors.combine());
    } finally {
      setIsLoading(false);
      abortControllerSet.delete(abortController);
    }
  }, [abortControllerSet, projectId]);

  useEffect(() => {
    fetchProject();
  }, [fetchProject]);

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  useEffect(() => () => cleanup(), [cleanup]);

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <h2 className="title mb-0">{project?.name || 'View Project'}</h2>
      <hr />
      <ServerErrorMessages messages={serverErrorMessages} />
      {isLoading ? (
        <PageLoading />
      ) : (
        <TabLayout tabLayoutOptions={tabLayoutOptions}>
          <Routes>
            <Route index element={<Navigate to="project-detail" replace={true} />} />
            <Route
              path="project-detail"
              element={
                <ProjectDetails
                  project={project}
                  breadcrumbsBase={breadcrumbsBase}
                  setBreadcrumbs={setBreadcrumbs}
                  isEdit={isEdit}
                />
              }
            />
            <Route
              path="project-rag-detail"
              element={
                <ProjectRAGDetails
                  ragReport={project?.ragReport}
                  breadcrumbsBase={breadcrumbsBase}
                  setBreadcrumbs={setBreadcrumbs}
                  isEdit={isEdit}
                />
              }
            />
            <Route path="*" element={<Navigate to="./" />} />
          </Routes>
        </TabLayout>
      )}
    </>
  );
}

export default Project;
