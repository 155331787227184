import classNames from 'classnames';
import axios from 'axios';
import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { RoutePath } from 'src/router';
import { Column, CellProps } from 'react-table';
import { generatePath } from 'react-router-dom';

import { useSelector } from 'src/store';
import { selectedCompanyIdSelector } from 'src/store/selectors/companySelector';

import { useForm } from 'react-hook-form';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { Table } from '@itm/shared-frontend/lib/components';
import { useFormErrors } from '@itm/shared-frontend/lib/hooks';
import { EditButton } from '@itm/shared-frontend/lib/components/buttons';
import { RequestSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';
import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';

import { DataAccessPermission, isDataAccessError } from '@itm/shared-frontend/lib/components/dataAccess';
import useDataAccessPermission from 'src/hooks/useDataAccessPermission';

import { clientPortalApi } from 'src/api';
import { getSchemeListByCompanyId } from 'src/api/biReporting/scheme';
import { getProjectListSearch, ProjectListSearchParams } from 'src/api/biReporting/project';

import { ProjectResponse, TableChangeParams, SelectOption, ServerError } from 'src/types';

import styles from 'src/assets/styles/TableFilters.module.scss';

type UseTableFiltersProps = {
  companyId: string;
  isDeferredRenderReady: boolean;
  setHasDataAccess: React.Dispatch<React.SetStateAction<boolean>>;
};
const TABLE_NAME = 'PROJECT_TABLE';
const CUSTOM_FILTERS_KEY = `${TABLE_NAME}_CUSTOM_FILTERS`;

function getFiltersFromLocalStorage(key: string) {
  const state = window.localStorage.getItem(key);
  try {
    return state ? JSON.parse(state) : null;
  } catch {
    return null;
  }
}

const formSchema = object().shape({
  schemeId: string().label('Scheme'),
});

function useTableFilters({ isDeferredRenderReady, setHasDataAccess }: UseTableFiltersProps) {
  const companyId = useSelector(selectedCompanyIdSelector);
  const [schemeOptions, setSchemeOptions] = useState<SelectOption[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [data, setData] = useState<ProjectResponse[]>([]);
  const prevTableChangeParamsRef = useRef<TableChangeParams<object>>();
  const abortControllerSet = useMemo<Set<AbortController>>(() => new Set(), []);

  const { register, control, formState, setValue, watch, setError, clearErrors } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'all',
  });
  const { serverErrorMessages, handleErrors } = useFormErrors(setError, clearErrors);
  const schemeId = watch('schemeId');

  const hasSavedState = useMemo<boolean>(
    () => Boolean(),
    //matchPath(`${RoutePath.dataManagementProjectEditRoot}/*`, previousLocation.pathname),
    [],
  );

  const CustomGlobalFilter = useMemo(
    () => (globalFilterElement: React.JSX.Element) => {
      return (
        <form className="columns is-multiline" name="ProjectListFiltersForm" noValidate>
          <div className="column">
            <div className="field">
              <label>
                <div className="is-flex">{globalFilterElement}</div>
              </label>
            </div>
          </div>
          {schemeOptions?.length !== 1 && (
            <div className="column">
              <Field
                inputClassName={classNames('is-fullwidth', { 'is-loading': !schemeOptions })}
                field="dropdown"
                placeholder={
                  !schemeOptions ? 'Loading' : !schemeOptions.length ? 'No available schemes' : 'Select a scheme'
                }
                autoComplete="off"
                options={schemeOptions}
                register={register('schemeId')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled={!schemeOptions || !schemeOptions.length}
                dropdownProps={{ isClearable: true }}
              />
            </div>
          )}
        </form>
      );
    },
    [control, formState.errors, register, schemeOptions],
  );

  const columns = useMemo<Column<ProjectResponse>[]>(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Code', accessor: 'code' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: () => null,
        id: 'action',
        disableSortBy: true,
        headerClassName: 'is-narrow',
        Cell: ({ row }: React.PropsWithChildren<CellProps<ProjectResponse, undefined>>) => {
          const project = row.original;

          const editProjectButtonLabel = `Edit ${project.name} project`;

          const editProjectRoute = generatePath(RoutePath.dataManagementProjectEdit, {
            projectId: project.id,
          });

          return (
            <EditButton aria-label={editProjectButtonLabel} title={editProjectButtonLabel} to={editProjectRoute} />
          );
        },
      },
    ],
    [],
  );

  const cleanup = useCallback(() => {
    abortControllerSet.forEach((abortController) => abortController.abort());
  }, [abortControllerSet]);

  const fetchSchemeList = useCallback(async () => {
    if (!companyId) return;

    setSchemeOptions(undefined);
    try {
      const res = await getSchemeListByCompanyId(companyId);
      const options = res.data
        .map(({ name, id }) => ({ label: name, value: id }))
        .sort((a, b) => a.label.localeCompare(b.label));

      if (options.length === 1) {
        setValue('schemeId', options[0].value);
      }
      setSchemeOptions(options);
    } catch (e) {
      if (e instanceof axios.Cancel) return;
      handleErrors(e as ServerError);
      setSchemeOptions([]);
    }
  }, [companyId, handleErrors, setValue]);

  const tableChangeHandler = useCallback(
    async (tableChangeParams: TableChangeParams<object>) => {
      cleanup();
      const abortController = new AbortController();
      abortControllerSet.add(abortController);
      prevTableChangeParamsRef.current = tableChangeParams;
      handleErrors();
      setIsLoading(true);
      try {
        const mainParams = new RequestSearchParamsAdapter(tableChangeParams);
        const extraParams: ProjectListSearchParams = {
          companyId,
          schemeId: schemeId || undefined,
        };
        const params = { ...mainParams, ...extraParams };
        const config = { signal: abortController.signal };
        const res = await getProjectListSearch(params, config);
        const { totalCount, items } = res.data;
        setPageCount(Math.ceil(totalCount / tableChangeParams.pageSize));
        setHasDataAccess(true);
        setData(items);
      } catch (e) {
        if (e instanceof axios.Cancel || !(e instanceof axios.AxiosError)) return;

        if (isDataAccessError(e.response)) {
          setHasDataAccess(false);
          setPageCount(0);
          setData([]);
          prevTableChangeParamsRef.current = undefined;
        } else {
          handleErrors(e);
        }
      } finally {
        setIsLoading(false);
        abortControllerSet.delete(abortController);
      }
    },
    [cleanup, abortControllerSet, handleErrors, companyId, schemeId, setHasDataAccess],
  );

  const fillFilters = useCallback(() => {
    const filters = getFiltersFromLocalStorage(CUSTOM_FILTERS_KEY);
    if (!filters) return;
    setValue('schemeId', filters.schemeId);
  }, [setValue]);

  useEffect(() => {
    if (!isDeferredRenderReady) return;
    if (hasSavedState) {
      fillFilters();
    } else {
      window.localStorage.removeItem(CUSTOM_FILTERS_KEY);
    }
  }, [fillFilters, hasSavedState, isDeferredRenderReady]);

  useEffect(() => {
    if (!prevTableChangeParamsRef.current) return;

    tableChangeHandler({ ...prevTableChangeParamsRef.current, pageIndex: 0 });
    window.localStorage.setItem(
      CUSTOM_FILTERS_KEY,
      JSON.stringify({
        schemeId,
      }),
    );
  }, [tableChangeHandler, schemeId]);

  useEffect(() => () => cleanup(), [cleanup]);

  useEffect(() => {
    if (!hasSavedState) {
      setValue('schemeId', '');
    }

    fetchSchemeList();
  }, [fetchSchemeList, hasSavedState, setValue]);

  return {
    data,
    columns,
    pageCount,
    hasSavedState,
    isLoading,
    tableChangeHandler,
    CustomGlobalFilter,
    serverErrorMessages,
  };
}

function ProjectList() {
  const { companyId, companyName, productId, hasDataAccess, isDeferredRenderReady, setHasDataAccess } =
    useDataAccessPermission();

  const {
    data,
    columns,
    pageCount,
    hasSavedState,
    isLoading,
    tableChangeHandler,
    CustomGlobalFilter,
    serverErrorMessages,
  } = useTableFilters({ companyId, isDeferredRenderReady, setHasDataAccess });

  const SuccessDataAccessButtonComponent = useMemo(
    () => () => (
      <button className="button is-interact" type="button" onClick={() => setHasDataAccess(true)}>
        Back to Project Search
      </button>
    ),
    [setHasDataAccess],
  );

  return (
    <>
      <h2 className="title mb-0">Project Search</h2>
      <hr />
      {hasDataAccess ? (
        isDeferredRenderReady && (
          <>
            <ServerErrorMessages messages={serverErrorMessages} />
            <div className={styles.Table}>
              <Table
                name={TABLE_NAME}
                data={data}
                columns={columns}
                pageCount={pageCount}
                isLoading={isLoading}
                onParamsChange={tableChangeHandler}
                hasSavedState={hasSavedState}
                CustomGlobalFilter={CustomGlobalFilter}
                disableFilters
              />
            </div>
          </>
        )
      ) : (
        <DataAccessPermission
          companyId={companyId}
          companyName={companyName}
          clientPortalApiInstance={clientPortalApi}
          productId={productId}
          SuccessButtonComponent={SuccessDataAccessButtonComponent}
        />
      )}
    </>
  );
}

export default ProjectList;
