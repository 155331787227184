import { biReportingApi } from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { RequestSearchParamsAdapter } from '@itm/shared-frontend/lib/utils';

import { ProjectSearchResponsePagedResponse, ProjectResponse } from 'src/types';

export type ProjectListSearchParams = {
  companyId: string;
  schemeId?: string;
};

export const getProjectListSearch = (
  params: RequestSearchParamsAdapter & ProjectListSearchParams,
  config: AxiosRequestConfig = {},
) => biReportingApi.get<ProjectSearchResponsePagedResponse>('/api/v1/projects/search', { ...config, params });

export const getProjectById = (projectId: string, config: AxiosRequestConfig = {}) =>
  biReportingApi.get<ProjectResponse>(`/api/v1/projects/${projectId}`, config);
