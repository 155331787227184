export { history } from './history';

export const RoutePath = {
  root: '/',
  loginRedirect: '/login',
  permissionDenied: '/permission-denied',

  // Data Management
  dataManagementRoot: '/data-management',
  dataManagementProjectList: '/data-management/project/list',
  dataManagementProjectEdit: '/data-management/:projectId/edit',

  // Report
  reportRoot: '/report',
  reportProjectList: '/report/list',
  reportCreate: '/report/create',

  // Analytics & Reports
  analyticsAndReports: '/analytics-and-reports',

  // Product Information
  productInfo: '/product-info',
};

export { GuardedRoute } from './GuardedRoute';
