import type { Breadcrumb } from '@itm/shared-frontend/lib/components/Breadcrumbs';

import { ResultFailType } from '@itm/shared-frontend';
export { ResultFailType };

export type {
  GlobalRole,
  TableSortParams,
  TableChangeParams,
  SelectOption,
  ServerValidationError,
  ServerValidationResponse,
  ServerError,
  FieldErrors,
  ServerFormErrors,
} from '@itm/shared-frontend/lib/types';

export enum TargetPlatform {
  AWS = 'AWS',
  AZURE = 'AZURE',
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Support = 'Support',
  Analyst = 'BIReporting_Analyst',
  Viewer = 'BIReporting_Viewer',
  ClientAdmin = 'BIReporting_ClientAdmin',
  Manager = 'BIReporting_Manager',
}

export type CompanyResponse = {
  id: string;
  name: string;
};

export type SchemeResponse = {
  id: string;
  name: string;
  companyId: string;
};

export type ReportTypeResponse = {
  type: string;
  name: string;
};

export type ProductInfoResponse = {
  id: string;
  description: string | null;
  version: string | null;
};

export type BreadcrumbProps = {
  breadcrumbsBase: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
  isBreadcrumbsReady: boolean;
};

export type RAGReportModel = {
  id: string;
  status: string;
  description: string;
  plannedCompletionDate?: string;
  projectStatus: string;
  percentCompleteEstimate: string;
  pmoComments?: string;
  marginRAG?: string;
  projectHealthRAG?: string;
  action?: string;
  exceptionHighlight?: string;
  exceptionCostBudget?: string;
  exceptionSalesBudget?: string;
  exceptionCostExceeded?: string;
  forecastedCostRT: string;
  finalRevenueForProject: string;
  actualRevenueToDate: string;
  lastHighlightReport: string;
};

export type RAGReportResponse = {
  id: string;
  status: string;
  description: string;
  plannedCompletionDate: string;
  projectStatus: string;
  percentCompleteEstimate: string;
  pmoComments: string;
  marginRAG: string;
  projectHealthRAG: string;
  action: string;
  exceptionHighlight?: string;
  exceptionCostBudget?: string;
  exceptionSalesBudget?: string;
  exceptionCostExceeded?: string;
  forecastedCostRT: string;
  finalRevenueForProject: string;
  actualRevenueToDate: string;
  lastHighlightReport: string;
};

export type ProjectResponse = {
  id: string;
  name: string;
  code: string;
  status: string;
  ragReport: RAGReportResponse;
};

export type ProjectSearchResponsePagedResponse = {
  totalCount: number;
  items: ProjectResponse[];
};

export type ReportResponse = {
  id: string;
  name: string;
  type: string;
  status: string;
  createdDate: string;
  createdByName: string;
};

export type ReportSearchResponsePagedResponse = {
  totalCount: number;
  items: ReportResponse[];
};

export type CreateReportDto = {
  companyId: string;
  reportType: ReportType;
};

export enum ReportType {
  RAGReport = 'RAGReport',
}
