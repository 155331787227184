import { Routes, Route, Navigate } from 'react-router-dom';

import ProjectListPage from './ProjectList';
import ProjectPage from './Project';

function DataManagementRoutes() {
  return (
    // "/data-management/*"
    <Routes>
      <Route index element={<Navigate to="list" replace={true} />} />

      <Route path="list" element={<ProjectListPage />} />
      <Route path=":projectId/edit/*" element={<ProjectPage isEdit={true} />} />

      <Route path="*" element={<Navigate to="./" />} />
    </Routes>
  );
}

export default DataManagementRoutes;
