import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFormErrors } from '@itm/shared-frontend/lib/hooks';
import { SuccessModal } from '@itm/shared-frontend/lib/components/modals';
import { LoadingButton } from '@itm/shared-frontend/lib/components/buttons';
import { Field, ServerErrorMessages } from '@itm/shared-frontend/lib/components/forms';
import type { Breadcrumb } from '@itm/shared-frontend/lib/components/Breadcrumbs';

import { updateRAGReport } from 'src/api/biReporting/ragReport';

import { RAGReportResponse, RAGReportModel, ServerError } from 'src/types';

type Props = {
  ragReport: RAGReportResponse | undefined;
  breadcrumbsBase: Breadcrumb[];
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[]>>;
  isEdit: boolean;
  isView?: boolean;
};

function ProjectRAGDetails({ ragReport, isEdit }: Props) {
  const [isModalActive, setIsModalActive] = useState(false);

  const formSchema = object().shape({
    status: string().trim().label('status'),
    plannedCompletionDate: string().trim().label('plannedCompletionDate'),
    projectStatus: string().trim().label('projectStatus'),
    percentCompleteEstimate: string().trim().label('percentCompleteEstimate').required(),
    pmoComments: string().trim().label('pmoComments'),
    marginRAG: string().trim().label('marginRAG'),
    projectHealthRAG: string().trim().label('projectHealthRAG'),
    action: string().trim().label('action'),
  });

  const { register, control, formState, handleSubmit, setValue, setError, clearErrors } = useForm({
    resolver: yupResolver(formSchema),
  });
  const { handleErrors, serverErrorMessages } = useFormErrors(setError, clearErrors);

  useEffect(() => {
    if (ragReport) {
      setValue('status', ragReport.status);
      setValue('plannedCompletionDate', ragReport.plannedCompletionDate || '');
      setValue('projectStatus', ragReport.projectStatus);
      setValue('percentCompleteEstimate', ragReport.percentCompleteEstimate || '');
      setValue('pmoComments', ragReport.pmoComments || '');
      setValue('marginRAG', ragReport.marginRAG || '');
      setValue('projectHealthRAG', ragReport.projectHealthRAG || '');
      setValue('action', ragReport.action || '');
    }
  }, [ragReport, setValue]);

  const onSubmit = handleSubmit(async (data) => {
    handleErrors();
    if (!ragReport) return;
    try {
      const updateData: RAGReportModel = {
        ...data,
        id: ragReport.id,
        status: ragReport.status,
        description: ragReport.description,
        plannedCompletionDate: data.plannedCompletionDate,
        projectStatus: ragReport.projectStatus,
        exceptionHighlight: ragReport.exceptionHighlight,
        exceptionCostBudget: ragReport.exceptionCostBudget,
        exceptionSalesBudget: ragReport.exceptionSalesBudget,
        exceptionCostExceeded: ragReport.exceptionCostExceeded,
        forecastedCostRT: ragReport.forecastedCostRT,
        finalRevenueForProject: ragReport.finalRevenueForProject,
        actualRevenueToDate: ragReport.actualRevenueToDate,
        lastHighlightReport: ragReport.lastHighlightReport,
      };
      await updateRAGReport(updateData);
      setIsModalActive(true);
    } catch (e) {
      handleErrors(e as ServerError);
    }
  });

  return (
    <>
      <ServerErrorMessages messages={serverErrorMessages} />
      <form name="ragReportDetailForm" onSubmit={onSubmit} noValidate>
        <div className="card mb-5">
          <div className="columns is-multiline">
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Status"
                field="input"
                placeholder="Status"
                autoComplete="off"
                register={register('status')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled
              />
            </div>
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Project Status"
                field="input"
                placeholder="Project Status"
                autoComplete="off"
                register={register('projectStatus')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
                disabled
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Planned Completion Date"
                field="datepicker"
                placeholder="Planned Completion Date"
                autoComplete="off"
                register={register('plannedCompletionDate')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="PMO Comments"
                field="input"
                placeholder="PMO Comments"
                autoComplete="off"
                register={register('pmoComments')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
            </div>
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Margin RAG"
                field="input"
                placeholder="Margin RAG"
                autoComplete="off"
                register={register('marginRAG')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Project Health RAG"
                field="input"
                placeholder="Project Health RAG"
                autoComplete="off"
                register={register('projectHealthRAG')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
            </div>
            <div className="column is-3-fullhd is-3-widescreen is-6-desktop is-12-tablet">
              <Field
                inputClassName="is-fullwidth"
                label="Action"
                field="input"
                placeholder="Action"
                autoComplete="off"
                register={register('action')}
                control={control}
                formSchema={formSchema}
                errors={formState.errors}
              />
            </div>
          </div>
        </div>
        {isEdit && (
          <div className="has-text-right">
            <div className="field">
              <LoadingButton type="submit" className="button is-interact" isLoading={formState.isSubmitting}>
                Update RAG Report
              </LoadingButton>
            </div>
          </div>
        )}
      </form>
      <SuccessModal
        isShowModal={isModalActive}
        closeModal={() => setIsModalActive(false)}
        Body={
          <>
            Congrats! The Project RAG Details
            <br />
            has been updated.
          </>
        }
        Footer={
          <Link className="button is-interact" to="/configuration/list">
            Continue
          </Link>
        }
      />
    </>
  );
}

export default ProjectRAGDetails;
