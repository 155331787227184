import { useEffect, useState } from 'react';

const useIsDeferredRenderReady = () => {
  const [isDeferredRenderReady, setIsDeferredRenderReady] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsDeferredRenderReady(true);
    });
  }, []);
  return isDeferredRenderReady;
};

export default useIsDeferredRenderReady;
