import getEnvVariable from './getEnvVariable';

import { TargetPlatform, SelectOption, ReportType } from 'src/types';

export const ENV_PREFIX = 'REACT_APP_' as const;

export const ENVIRONMENT = getEnvVariable('ENVIRONMENT');
export const TARGET_PLATFORM = getEnvVariable('TARGET_PLATFORM');
export const AUTH_API_URL = getEnvVariable('AUTH_API_URL');
export const BI_REPORTING_API_URL = getEnvVariable('BI_REPORTING_API_URL');
export const CLIENT_PORTAL_API_URL = getEnvVariable('CLIENT_PORTAL_API_URL');

export const isProductionEnv = ENVIRONMENT === 'PROD';
export const isAzureTargetPlatform = TARGET_PLATFORM === TargetPlatform.AZURE;

const todayDate = new Date();
export const minus18YearsDate = new Date(
  Date.UTC(todayDate.getFullYear() - 18, todayDate.getMonth(), todayDate.getDate()),
);

export const reportTypeOptions: SelectOption[] = [{ label: 'RAG Report', value: ReportType.RAGReport }];
